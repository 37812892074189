'use client';

import React from 'react';
import { Button, ButtonProps, SxProps, Theme, useTheme } from '@mui/material';
import deepmerge from 'deepmerge';

export default function BaseButton(props: ButtonProps) {
    const theme = useTheme();
    const { children, disableElevation = true, endIcon, size = 'medium', startIcon, sx = {}, ...other } = props;
    return (
        <Button
            endIcon={endIcon}
            size={size}
            // disableRipple
            variant="outlined"
            startIcon={startIcon}
            {...other}
            sx={deepmerge<SxProps<Theme>>( //This ensures that both sets of styles are applied to the component, with the custom styles taking precedence if there are conflicts.
                {
                    borderRadius: '100px',
                    border: '1px solid var(--text-gray-07000, #000)',
                    color: 'grayscale.gray07',
                    boxShadow: disableElevation ? 'none' : '0px 8px 12px 0px rgba(112, 15, 198, 0.12)',
                    flex: '0 0 auto',
                    // textTransform: 'unset',
                    py: 0,

                    '& .MuiButton-startIcon': {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px',
                        ml: '-12px',
                        mr: 0,
                    },
                    '& .MuiButton-endIcon': {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '32px',
                        height: '32px',
                        ml: '4px',
                        mr: '-12px',
                    },
                    ...(size === 'large' && {
                        ...theme.typography.h2_20Medium,
                        height: '44px',
                        px: '28px',
                    }),
                    ...(size === 'medium' && {
                        ...theme.typography.h2_12Medium,
                        height: '36px',
                        px: '24px',
                    }),
                    ...(size === 'small' && {
                        ...theme.typography.h2_12Medium,
                        height: '32px',
                        px: '20px',
                    }),
                },
                sx
            )}
        >
            {children}
        </Button>
    );
}
