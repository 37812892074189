'use client';

import React from 'react';
import { DialogContentText, DialogContentTextProps } from '@mui/material';

export default function BaseDialogContentText(props: DialogContentTextProps) {
  const { children, sx = {}, ...other } = props;

  return (
    <DialogContentText
      variant="h2_14Medium"
      {...other}
      sx={{
        p: 0,
        textAlign: 'center',
        m: 0,
        ...sx,
      }}
    >
      {children}
    </DialogContentText>
  );
}
