'use client';

import React from 'react';
import { DialogActions, DialogActionsProps } from '@mui/material';

export default function BaseDialogActions(props: DialogActionsProps) {
  const { children, disableSpacing = true, sx = {}, ...other } = props;

  return (
    <DialogActions
      disableSpacing={disableSpacing}
      {...other}
      sx={{
        gap: '12px',
        m: 0,
        px: 0,
        ...sx,
      }}
    >
      {children}
    </DialogActions>
  );
}
