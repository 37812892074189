import { Box, Typography } from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

type fallBackProps = {
    width?: number | string;
    maxWidth?: number | string;
    maxHeight?: string | number;
    minHeight?: string | number;
    height?: number | string;
    src?: string;
    alt?: string;
    title?: string;
    description?: string;
    marginRight?: string;
    style?: React.CSSProperties;
};
const FallbackUI: React.FC<fallBackProps> = (props) => {
    const { width = '140px', maxWidth = '140px', maxHeight = 'auto', minHeight = 'auto', height = '140px', src = '', alt = ' ', title = '', marginRight, description = 'No Image Available', style, ...sx } = props;
    return (
        <Box
            sx={{
                minWidth: { width },
                maxWidth: { maxWidth },
                height: { height },
                maxHeight: { maxHeight },
                minHeight: { minHeight },
                marginRight: { marginRight },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ddd',
                flexDirection: 'column',
                ...sx,
            }}
            style={{ ...style }}
        >
            <BrokenImageIcon sx={{ fontSize: 40, color: '#aaa' }} />
            <Typography
                variant="h2_12Regular"
                color="textSecondary"
            >
                {description}
            </Typography>
        </Box>
    );
};

export default FallbackUI;
