'use client';

import React from 'react';
import { Box, BoxProps } from '@mui/material';

export default function BaseSectionHeader(props: BoxProps) {
  const { children, sx = {}, ...other } = props;

  return (
    <Box
      {...other}
      sx={{
        paddingLeft: '20px',
        paddingRight: '20px',
        ...sx
      }}
    >
      {children}
    </Box>
  );
}
