"use client";

import React from 'react';
import {
    DialogTitle,
    DialogTitleProps
} from "@mui/material";

export default function BaseDialogTitle(props: DialogTitleProps) {
    const {
        children,
        sx = {},
        ...other
    } = props;

    return (
        <DialogTitle
            variant="h3"
            component="h3"
            {...other}
            sx={{
                p: 0,
                textAlign: "center",
                m: 0,
                ...sx,
            }}
        >
            {children}
        </DialogTitle>
    );
}
