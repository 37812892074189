"use client";

import React from 'react';
import {
    FilledInputProps,
    InputProps,
    OutlinedInputProps,
    TextField,
    TextFieldProps,
    useTheme
} from "@mui/material";
import deepmerge from "deepmerge";

export default function BaseInput(props: TextFieldProps) {
    const theme = useTheme();
    const {
        size = "small",
        fullWidth = true,
        InputProps = {},
        //sx = {},
        ...other
    } = props;

    return (
        <TextField
            fullWidth={fullWidth}
            size={size}
            {...other}
            InputProps={deepmerge<
                | Partial<FilledInputProps>
                | Partial<OutlinedInputProps>
                | Partial<InputProps>
            >(
                {
                    sx: {
                        ...theme.typography.h2_14Medium,
                        color: theme.palette.secondary.main,
                        height: "46px",
                        py: 0,
                        input: {
                            "&:focus": {
                                color: theme.palette.text.primary,
                            },
                        },
                    },
                },
                InputProps
            )}
        />
    );
}
