'use client';

import React from 'react';
import { ReactNode, forwardRef } from 'react';

import MuiAlert, {
  AlertColor,
  AlertProps as MuiAlertProps,
} from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export type BaseAlertProps = Omit<MuiAlertProps, 'children'> & {
  message: ReactNode;
  title?: ReactNode;
  severity?: AlertColor;
};

// eslint-disable-next-line react/display-name
const BaseAlert = forwardRef<HTMLDivElement, BaseAlertProps>(
  ({ title, message, ...restProps }, ref) => (
    <MuiAlert
      ref={ref}
      {...restProps}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
    </MuiAlert>
  )
);

export default BaseAlert;
