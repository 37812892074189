'use client';

import React from 'react';
import { Box, BoxProps } from '@mui/material';

export default function BaseSectionContent(props: BoxProps) {
  const { children, sx, ...other } = props;

  return (
    <Box
      {...other}
      sx={{
        pt: '16px', //this will change if has any requirement
        width: '100%',
        // height: '100vh',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
