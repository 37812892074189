import { safeAttributes, safeTags } from '../constants/typedef';
import DOMPurify from 'dompurify';

const handleLogicText = {
    formatContentPost,
    formatText,
    shortText,
    formatContentWithOutShort,
    shortTextKoreanAndLatin,
    sanitizeHTML,
};

function sanitizeHTML(htmlString: string): string {
    return DOMPurify.sanitize(htmlString, {
        ALLOWED_TAGS: safeTags,
        ALLOWED_ATTR: safeAttributes,
    }) as string;
}

function shortTextKoreanAndLatin(text: string, limit: number) {
    const elements = formatText(sanitizeHTML(text)).split(/(<[^>]+>|[\u0000-\uFFFF])/); // Split by tags and all characters
    let txt = '';
    let length = 0;
    let isInTag = false;

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element.startsWith('<') && element.endsWith('>')) {
            txt += element;
            continue;
        }

        for (let j = 0; j < element.length; j++) {
            if (element[j] === '<') {
                isInTag = true;
            } else if (element[j] === '>') {
                isInTag = false;
            }

            if (!isInTag) {
                length++;
            }

            if (length <= limit) {
                txt += element[j];
            } else {
                txt += '... ';
                return txt;
            }
        }
    }

    return txt;
}
function shortText(text: string, limit: number) {
    const elements = formatText(sanitizeHTML(text)).split(/[\s]+/);
    let txt = '';
    const tag =
        elements
            .join('')
            .match(/(<([^>]+)>)/gi)
            ?.join('').length || 0;
    for (let i = 0; i < elements.length; i++) {
        const next = ' ' + elements[i];
        if (txt.length + next.length < limit + tag) txt += ' ' + elements[i];
        else {
            txt += ' ' + elements[i] + ' ' + '... ';
            break;
        }
    }
    return txt;
}

function formatText(text: string) {
    const elements = (sanitizeHTML(text) || '')?.replace(/\n/g, ' _breakline_ ').split(/[\s]+/);
    let txt: string = '';
    for (let i = 0; i < elements.length; i++) {
        if (elements[i] === '_breakline_') {
            if (elements[i - 1] !== '_breakline_') {
                txt += ' ' + elements[i];
            }
            if (elements[i - 1] === '_breakline_' && elements[i - 2] !== '_breakline_') {
                txt += ' ' + elements[i];
            }
        } else {
            txt += ' ' + elements[i];
        }
    }
    return txt.slice(1).replace(/_breakline_/g, '<br/>');
}

function formatContentPost(string: string, show: boolean, hrefSearch?: string): string {
    const content = formatText(sanitizeHTML(string));
    const shortContent = shortText(sanitizeHTML(string), 120);
    return (show ? content : shortContent)
        ?.replace(/(^|\s)#([^\s]+)/gi, `$1<a href='${hrefSearch ?? '/search?s=$2'}' style='color:#2073F6; text-decoration: none'>#$2</a>`) //search hashtag if needed
        .replace(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi, "<a target='_blank' style='color:#2073F6;' href='$1'>$1</a>")
        .replace(/\n/g, '<br/>')
        .replace(
            /<behance><a[^>]*>(.*?)<\/a><\/behance>/g,
            "<a target='_blank' href='$1'><img src='https://i.imgur.com/dnhHtYW.png' style='width:24px;height:24px;border-radius:4px;margin:2px;' /></a>"
        )
        .replace(
            /<youtube><a[^>]*>(.*?)<\/a><\/youtube>/g,
            "<a target='_blank' href='$1'><img src='https://i.imgur.com/B3LGY8T.png' style='width:24px;height:24px;border-radius:4px;margin:2px;' /></a>"
        );
}

function formatContentWithOutShort(string: string): string {
    const content = formatText(sanitizeHTML(string));

    return content
        ?.replace(/(^|\s)#([^\s]+)/gi, `$1<a href='${'/search?s=$2'}' style='color:#2073F6; text-decoration: none'>#$2</a>`) //search hashtag if needed
        .replace(/(^|[\s>])(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi, (match, p1, p2) => `${p1}<a target='_blank' style='color:#2073F6;' href='${p2}'>${p2}</a>`)
        .replace(/<a\s(?!.*?target=)(.*?)>/gi, "<a target='_blank' $1>")
        .replace(/\n/g, '<br/>')
        .replace(
            /<behance><a[^>]*>(.*?)<\/a><\/behance>/g,
            "<a target='_blank' href='$1'><img src='https://i.imgur.com/dnhHtYW.png' style='width:24px;height:24px;border-radius:4px;margin:2px;' /></a>"
        )
        .replace(
            /<youtube><a[^>]*>(.*?)<\/a><\/youtube>/g,
            "<a target='_blank' href='$1'><img src='https://i.imgur.com/B3LGY8T.png' style='width:24px;height:24px;border-radius:4px;margin:2px;' /></a>"
        );
}

export default handleLogicText;
