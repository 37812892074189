'use client';

import React from 'react';
import { DialogContent, DialogContentProps } from '@mui/material';

export default function BaseDialogContent(props: DialogContentProps) {
  const { children, sx = {}, ...other } = props;

  return (
    <DialogContent
      {...other}
      sx={{
        m: 0,
        px: 0,
        ...sx,
      }}
    >
      {children}
    </DialogContent>
  );
}
