'use client';

import React from 'react';
import { Box, BoxProps } from '@mui/material';

export default function BaseSectionFooter(props: BoxProps) {
  const { children, sx, ...other } = props;

  return (
    <Box
      {...other}
      sx={{
        width: '100%',
        ...sx
      }}
    >
      {children}
    </Box>
  );
}
