import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swiper.css';
import { Navigation, Pagination } from 'swiper/modules';
import React, { useState, useRef, useEffect, CSSProperties, forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Grid, Box, Typography, useTheme } from '@mui/material';
import { AbsoluteValue, NumOfImage } from '@/app/constants/typedef';
import { IMedia } from '@/app/models/media/IMedia';
import CenteredBox from '../CenterBox';
import { SwiperModule, SwiperOptions } from 'swiper/types';
import { MAX_SCREEN_WIDTH } from '@/app/constants/layoutConfig';

// Register Swiper modules
SwiperCore.use([Navigation]);

interface SliderMediaProps extends SwiperOptions {
    data: IMedia[] | any;
    module?: SwiperModule[];
    showNumbersImg: boolean;
    showButtonNextPrev?: boolean;
    clickable?: boolean;
    onSlideChange?: (index: number) => void;
    onTouchMoveStart?: (value: boolean) => void;
    bulletType?: 'default' | 'white';
    classForSlideItem?: boolean;
    onActiveIndexChange?: (index: number) => void;
    children: (image: IMedia | any, index: number, hasError: boolean, handleError: () => void, onReadyVideo?: () => void) => React.ReactNode;
}

const SliderMedia = forwardRef<SwiperCore | null, SliderMediaProps>(
    (
        {
            bulletType = 'default',
            module = [],
            data = [],
            showNumbersImg,
            showButtonNextPrev = false,
            onTouchMoveStart,
            clickable = false,
            onSlideChange,
            children,
            classForSlideItem,
            onActiveIndexChange,
            ...other
        },
        ref
    ) => {
        const [swiper, setSwiper] = useState<SwiperCore | null>(null);
        const [activeIndex, setActiveIndex] = useState(0);
        const [errorIndices, setErrorIndices] = useState<number[]>([]); // State to track error indices
        const navigationPrevRef = useRef<HTMLDivElement>(null);
        const navigationNextRef = useRef<HTMLDivElement>(null);
        const timeout = useRef<NodeJS.Timeout | null>(null);

        useEffect(() => {
            if (swiper) {
                const handleSlideChange = () => {
                    const currentIndex = swiper.realIndex;
                    const currentSlide = swiper.slides[swiper.previousIndex];
                    const videoElement = currentSlide?.querySelector('video');
                    if (videoElement && !videoElement.paused) {
                        videoElement.pause();
                        // videoElement.currentTime = 0;
                    }

                    setActiveIndex(currentIndex);
                    if (onSlideChange) {
                        onSlideChange(currentIndex);
                    }
                };

                swiper.on('slideChange', handleSlideChange);

                return () => {
                    swiper.off('slideChange', handleSlideChange);
                };
            }
        }, [swiper, onSlideChange, activeIndex]);

        const swipeRight = () => {
            if (swiper) {
                swiper.slideNext(500, true);
            }
        };

        const swipeLeft = () => {
            if (swiper) {
                swiper.slidePrev(500, true);
            }
        };

        const handleImageError = (index: number) => {
            setErrorIndices((prev) => [...prev, index]);
        };

        const handleVideoReady = (index: number) => {
            if (swiper) {
                setTimeout(() => {
                    if (swiper?.updateAutoHeight) {
                        swiper?.updateAutoHeight();
                    }
                }, 100);
            }
        };

        const onSwiper = (swiperInstance: SwiperCore) => {
            setSwiper(swiperInstance);
            if (ref && typeof ref === 'function') {
                ref(swiperInstance);
            } else if (ref) {
                (ref as React.MutableRefObject<SwiperCore | null>).current = swiperInstance;
            }
        };

        const onTouchMove = (e: SwiperCore) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
            onTouchMoveStart?.(true);
        };

        const onTouchEnd = (e: SwiperCore) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
            timeout.current = setTimeout(() => {
                onTouchMoveStart?.(false);
            }, 400);
        };

        const imageItems = data.slice(0, NumOfImage.MAX_IMAGES_PER_ROW).map((image: any, index: any) => (
            <SwiperSlide
                key={index}
                id={`${index}`}
                className={'swiper-slide-custom'}
            >
                <CenteredBox>
                    {children(
                        image,
                        index,
                        errorIndices.includes(index),
                        () => handleImageError(index),
                        () => handleVideoReady(index)
                    )}
                </CenteredBox>
            </SwiperSlide>
        ));
        return (
            <Grid item>
                <Grid
                    item
                    className="thumbTiles"
                    style={{ backgroundColor: 'grayscale.gray02', maxWidth: MAX_SCREEN_WIDTH }}
                >
                    <Swiper
                        pagination={true}
                        observer={true}
                        observeParents={true}
                        observeSlideChildren={true}
                        modules={[Pagination, ...module]}
                        className={`swiper-container ${bulletType}`}
                        style={{ position: 'relative' }}
                        centeredSlides={false}
                        initialSlide={0}
                        spaceBetween={10}
                        loop={false}
                        onResize={(e) => e.updateAutoHeight()} // fixing shaking UI
                        autoHeight={true}
                        onSwiper={onSwiper}
                        onTouchMove={(swiper) => onTouchMove(swiper)}
                        onTouchEnd={onTouchEnd}
                        onTouchMoveOpposite={(swiper) => onTouchMove(swiper)}
                        breakpoints={{ 0: { slidesPerView: 1 }, 768: { slidesPerView: 1 } }}
                        {...other}
                    >
                        {imageItems}
                        {showNumbersImg && (
                            <Box
                                sx={{
                                    minWidth: '32px',
                                    minHeight: '24px',
                                    top: 10,
                                    borderRadius: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    right: 20,
                                    lineHeight: 0,
                                    zIndex: 8,
                                    color: 'white',
                                    position: 'absolute',
                                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '7px 8px',
                                }}
                            >
                                <Typography
                                    variant="h2_10Medium"
                                    sx={{ lineHeight: '100%' }}
                                >{`${activeIndex + 1}/${data.length}`}</Typography>
                            </Box>
                        )}
                        {showButtonNextPrev && (
                            <>
                                <Box
                                    ref={navigationPrevRef}
                                    className="swiper-button-prev"
                                    onClick={swipeLeft}
                                    style={{ display: activeIndex > AbsoluteValue.Number ? '' : 'none' }}
                                />
                                <Box
                                    ref={navigationNextRef}
                                    className="swiper-button-next"
                                    onClick={swipeRight}
                                />
                            </>
                        )}
                    </Swiper>
                </Grid>
            </Grid>
        );
    }
);

export default SliderMedia;
