import { Box, CardMedia, SxProps, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import deepmerge from 'deepmerge';

interface YouTubeThumbnailProps {
    url: string;
    onError?: (hasError: boolean) => void;
    sx?: SxProps<Theme>;
}

export const getYouTubeThumbnail = (youtubeUrl: string): string | null => {
    const videoId = extractVideoId(youtubeUrl);
    if (videoId) {
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
    return null;
};

const extractVideoId = (url: string): string | null => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
};

const YouTubeThumbnail: React.FC<YouTubeThumbnailProps> = ({ url, onError, sx = {} }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);


    useEffect(() => {
        if (!thumbnailUrl) onError?.(true);
        else onError?.(false);
    }, [thumbnailUrl]);

   

    React.useEffect(() => {
        const thumbnail = getYouTubeThumbnail(url);
        setThumbnailUrl(thumbnail);
    }, [url]);

    return (
        <Box
            id="thumbnail-ytb"
            sx={deepmerge<SxProps<Theme>>(
                {
                    borderRadius: 2.5,
                    mt: 10,
                    overflow: 'hidden',
                },
                sx
            )}
        >
            {thumbnailUrl && (
                <CardMedia
                    loading="lazy"
                    component={'img'}
                    src={thumbnailUrl}
                    alt=""
                    sx={{ borderRadius: 2.5, margin: '-10% 0', width: '100%' }}
                ></CardMedia>
            )}
        </Box>
    );
};

export default YouTubeThumbnail;
