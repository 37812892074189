'use client';

import { useRouter } from 'next/navigation';
import React, { createContext, useContext, useState, ReactNode, FC } from 'react';
import { useHandleBackOrRedirect } from '../hooks/useDetectHistoryRouter';

interface AlertDialogState {
    title: string;
    message: string;
}

interface ErrorDialogState {
    title: string;
    message: string;
    showCancel: boolean;
    showAccept: boolean;
    onCancel?: () => void;
    onAccept?: () => void;
    cancelText: string;
    acceptText: string;
}

interface ErrorDialogActions {
    open: (config: Partial<ErrorDialogState>) => void;
    close: () => void;
}

interface AlertDialogActions {
    open: (url?: string) => void;
    close: (hasRedirect?: boolean) => void;
}

interface LoadingDialogActions {
    open: (url?: string) => void;
    close: () => void;
}

interface EditPostDialogActions {
    open: (url?: string) => void;
    close: () => void;
}

interface StrictlyDialogActions {
    open: () => void;
    close: () => void;
}

interface ModalEnterShippingAddress {
    open: () => void;
    close: () => void;
}

interface GlobalDialogContextType {
    isDialogOpen: boolean;
    isDialogLoadingOpen: boolean;
    isDialogEditPostOpen: boolean;
    isStrictlyDialogOpen: boolean;
    isModalEnterShippingAddress: boolean;
    alertDialog: AlertDialogState & AlertDialogActions;
    loadingDialog: LoadingDialogActions;
    editPostDialog: EditPostDialogActions;
    strictlyDialog: StrictlyDialogActions;
    ModalEnterShippingAddress: ModalEnterShippingAddress;
}

const GlobalDialogContext = createContext<GlobalDialogContextType | undefined>(undefined);

interface GlobalDialogProviderProps {
    children: ReactNode;
}

export const GlobalDialogProvider: FC<GlobalDialogProviderProps> = ({ children }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogLoadingOpen, setIsDialogLoadingOpen] = useState(false);
    const [isDialogEditPostOpen, setIsDialogEditPostOpen] = useState(false);
    const [isStrictlyDialogOpen, setIsStrictlyDialogOpen] = useState(false);
    const [isModalEnterShippingAddress, setIsModalEnterShippingAddress] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>({ title: 'Warning', message: '' });
    const [errorDialogState, setErrorDialogState] = useState<ErrorDialogState>({
        title: '',
        message: '',
        showCancel: false,
        showAccept: true,
        cancelText: '취소',
        acceptText: '확인',
    });

    const router = useRouter();
    const actionBack = useHandleBackOrRedirect('/');
    const alertDialogActions: AlertDialogActions = {
        open: (url?: string) => {
            setIsDialogOpen(true);
            if (url) router.push(url);
        },
        close: (hasRedirect = true) => {
            if (hasRedirect) actionBack();
            setIsDialogOpen(false);
        },
    };

    const loadingDialogActions: LoadingDialogActions = {
        open: () => {
            setIsDialogLoadingOpen(true);
        },
        close: () => {
            setIsDialogLoadingOpen(false);
        },
    };

    const editPostDialogActions: EditPostDialogActions = {
        open: (url?: string) => {
            setIsDialogEditPostOpen(true);
            if (url) router.push(url);
        },
        close: () => {
            setIsDialogEditPostOpen(false);
        },
    };

    const strictlyDialogActions: StrictlyDialogActions = {
        open: () => {
            setIsStrictlyDialogOpen(true);
        },
        close: () => {
            setIsStrictlyDialogOpen(false);
        },
    };

    const ModalEnterShippingAddress: ModalEnterShippingAddress = {
        open: () => {
            setIsModalEnterShippingAddress(true);
        },
        close: () => {
            setIsModalEnterShippingAddress(false);
        },
    };

    return (
        <GlobalDialogContext.Provider
            value={{
                isDialogOpen,
                isDialogLoadingOpen,
                isDialogEditPostOpen,
                isStrictlyDialogOpen,
                isModalEnterShippingAddress,
                alertDialog: { ...alertDialogState, ...alertDialogActions },
                loadingDialog: { ...loadingDialogActions },
                editPostDialog: { ...editPostDialogActions },
                strictlyDialog: { ...strictlyDialogActions },
                ModalEnterShippingAddress: { ...ModalEnterShippingAddress },
            }}
        >
            {children}
        </GlobalDialogContext.Provider>
    );
};

export function useGlobalDialog(): GlobalDialogContextType {
    const context = useContext(GlobalDialogContext);

    if (context === undefined) {
        throw new Error('useGlobalDialog must be used within a GlobalDialogProvider');
    }

    return context;
}
