'use client';

import React, { forwardRef } from 'react';
import { Paper, PaperProps } from '@mui/material';

const BaseSection = forwardRef<HTMLDivElement, PaperProps>(function BaseSection(props, ref) {
  const { children, sx = {}, ...other } = props;

  return (
    <Paper
      ref={ref}
      elevation={0}
      {...other}
      sx={{
        minWidth: 'auto',
        width: '100%',
        overflow: 'hidden',
        height: 'auto',
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
});

export default BaseSection;
