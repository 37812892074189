'use client';

import React, { forwardRef, ElementType } from 'react';
import { Dialog, DialogProps, PaperProps } from '@mui/material';
import deepmerge from 'deepmerge';

const BaseDialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
    const { children, PaperProps = {}, sx = {}, open, ...other } = props;

    return (
        <Dialog
            aria-modal
            aria-hidden={false}
            ref={ref}
            open={open}
            fullWidth={true}
            {...other}
            PaperProps={deepmerge<Partial<PaperProps<ElementType<any, keyof JSX.IntrinsicElements>>>>(
                {
                    elevation: 0,
                    sx: {
                        gap: '24px',
                        height: '100%',
                        // overflowX: 'hidden'
                    },
                },
                PaperProps
            )}
            sx={{ ...sx }}
        >
            {children}
        </Dialog>
    );
});

export default BaseDialog;
